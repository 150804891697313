import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state:{
    help:'managerbdpho@gmail.com',
    technicalSupport:'md.imran300028@gmail.com',
    siteTitle:'',
    fbLikeBox:'',
    registrationRules:[],
    visitorCounter:'',
    divisions:[],
    participants:[],
    centralMembers:[],
    syllabus:[],
    samplePQ: [
      {id:1, category: 'Category-A', url:'https://admin.bdpho.org/assets/uploads/file-manager/2859_1735802414_.pdf'},
      {id:2, category: 'Category-B', url:'https://admin.bdpho.org/assets/uploads/file-manager/6585_1735802433_.pdf'},
      {id:3, category: 'Category-C', url:'https://admin.bdpho.org/assets/uploads/file-manager/5361_1735802450_.pdf'},
      {id:4, category: 'Category-D', url:'https://admin.bdpho.org/assets/uploads/file-manager/1686_1735802467_.pdf'},
    ],
    resources:[],
    pastPapers:[],
    pastEvents:[],
    info:[],
    notices:[],
    noticeCount:null,
    organizers:[],
    images:[],
    videos:[],
    questionPaper:[],
    questions:[],
    examStatus:null,
    studentAnswers:[],
    answerScriptId:null,
    userIP:null
  },
  getters:{
    getHelp(state){return state.help;},
    getTechnicalSupport(state){return state.technicalSupport;},
    getInfo(state){return state.info[0];},
    getNotices(state){return state.notices[0];},
    getNoticeCount(state){return state.noticeCount;},
    getDivisions(state){return state.divisions[0];},
    getSyllabus(state){return state.syllabus[0]['original'];},
    getSampleQP(state){return state.samplePQ;},
    getPastPapers(state){return state.pastPapers[0];},
    getPastEvents(state){return state.pastEvents[0];},
    getSiteTitle(state){return state.siteTitle;},
    getFBLikeBox(state){return state.fbLikeBox;},
    getVisitorCounter(state){return state.visitorCounter;},
    getRegistrationRules(state){return state.registrationRules[0];},
    getQuestionPaper(state){return state.questionPaper[0];},
    getQuestions(state){return state.questions[0];},
    getExamStatus(state){return state.examStatus;},
    getStudentAnswers(state){return state.studentAnswers;},
    getAnswerScriptId(state){return state.answerScriptId;},
    getUserIP(state){return state.userIP;},
  },
  mutations:{
    addProperty(state,item){
      state.info.push(item);
    },

    addNotices(state,notices){
      state.notices.push(notices);
    }
  },
  actions:{}
});
